import React, { useContext } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import WidgetLoader from "../components/widgets/WidgetLoader"
import { BrinkContext } from "../components/context/BrinkContext"
import styled from "styled-components"

const WidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
`

const HomePage = ({ data: { sanityStartPage }, pageContext }) => {
  const { widgets, metaDescription, metaTitle } = sanityStartPage
  const { languageCode } = useContext(BrinkContext)

  return (
    <Layout
      meta={
        metaTitle && metaDescription
          ? {
            title: metaTitle[languageCode] || metaTitle.en,
            description: metaDescription[languageCode] || metaDescription.en
          }
          : { title: "COMIS" }
      }
      pageContext={pageContext}
      invertedHeader
      noHeaderBorder
    >
      {widgets && widgets.length && <WidgetContainer><WidgetLoader widgets={widgets} /></WidgetContainer>}
    </Layout>
  )
}

export default HomePage

export const query = graphql`
  query {
    sanityStartPage {
      metaTitle {
        en
        sv
        de
        fr
      }
      metaDescription {
        en
        sv
        de
        fr
      }
      widgets {
        ...TextWidget
        ...Grid
        ...Hero
        ...ProductSlider
        ...UspWidget
        ...Image
        ...Video
        ...IconGrid
        ...Instruction
        ...Entry
        ...NewsletterSignup
        ...MarqueeBanner
        ...UgcCarousel
        ...BenefitGrid
        ...CollectionGrid
        ...UspCarousel
        ...ReviewCarousel
        ...ImageGrid
      }
    }
  }
`
